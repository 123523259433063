import {
  CumulioComponent,
  CumulioContext,
  initialState,
  reducer,
  ScreenMode,
} from 'react-cumulio'
import { RouteComponentProps } from '@reach/router'
import React, { FC, useReducer } from 'react'
import { HeadingSecondary } from '@awell/ui-kit/components/Typography'
import { isEmpty, isNil } from 'lodash'
import { useStyles } from './useStyles'
import { PathwayDashboard } from '../../generated/types-orchestration'

interface Props {
  dashboards?: PathwayDashboard | null
}

export const ClinicalDashboard: FC<RouteComponentProps & Props> = ({
  dashboards,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const useDashboard = !isNil(dashboards) && !isEmpty(dashboards?.dashboard_ids)
  const classes = useStyles()

  return (
    <div className={classes.dashboardItems}>
      {useDashboard && (
        <CumulioContext.Provider value={{ state, dispatch }}>
          {dashboards?.dashboard_ids.map(dashboardId => (
            <CumulioComponent
              key={dashboardId}
              dashboardId={dashboardId}
              screenmode={ScreenMode.DESKTOP}
              language='en'
              authKey={dashboards?.cumulio_auth_id}
              authToken={dashboards?.cumulio_auth_token}
            />
          ))}
        </CumulioContext.Provider>
      )}
      {!useDashboard && (
        <div className={classes.noDashboardText}>
          <HeadingSecondary variant='subHeadline'>
            No dashboards are configured for this pathway.
          </HeadingSecondary>
        </div>
      )}
    </div>
  )
}

ClinicalDashboard.displayName = 'ClinicalDashboard'
