import React, { type ReactElement } from 'react'
import { makeStyles } from '@material-ui/core'
import { colors, spacing } from '../../utils/style-guide'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
    [theme.breakpoints.up('md')]: {
      paddingLeft: spacing.m,
      paddingRight: spacing.m,
    },
  },
  border: {
    borderBottom: `2px solid ${colors.brand10}`,
  },
}))

interface RibbonProps {
  children: ReactElement | Array<Element>
  noBorder?: boolean
}

export const Ribbon: React.FC<RibbonProps> = ({
  children,
  noBorder = false,
}) => {
  const classes = useStyles()

  return (
    <section
      className={classnames([
        classes.container,
        noBorder ? null : classes.border,
      ])}
    >
      {children}
    </section>
  )
}

Ribbon.displayName = 'Ribbon'
