import { type Maybe } from '@awell/libs-web/types'
import { isEmpty, isNil } from 'lodash'
import React, { useEffect, useState, type FC, type ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import {
  initialPatientProfile,
  useCreatePatient,
} from '../../../../hooks/useCreatePatient'
import { usePatientDemographics } from '../../../../hooks/usePatientDemographics'
import { type Patient, languageOptions, type PatientProfile } from '../../types'
import { ChooseFromMultiplePatientOptions } from './helper/ChooseFromMultiplePatientOptions'
import { ImportAndCreatePatientForm } from './helper/ImportAndCreatePatientForm'
import { ShowCreatedPatientAndCTA } from './helper/ShowCreatedPatientAndCTA'

interface ImportingStepProps {
  patientProfiles: Array<Maybe<PatientProfile>>
  patientIdentifier: string
  onClickGoToFirstStep: () => void
  queryId: string
}

export const ReviewAndCreatePatientStep: FC<ImportingStepProps> = ({
  patientIdentifier,
  patientProfiles,
  onClickGoToFirstStep,
  queryId,
}): ReactElement => {
  const [selectedProfileIndex, setSelectedProfileIndex] = useState<number>()
  const [isMultipleProfileFlow, setIsMultipleProfileFlow] =
    useState<boolean>(false)

  const [createdPatient, setCreatedPatient] = useState<Patient>()
  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: initialPatientProfile,
  })
  const { createPatient } = useCreatePatient()
  const { updatePatientDemographicsQuery } = usePatientDemographics()

  const convertToISOFormat = (dateString: string): string | undefined => {
    return !isNil(dateString) && !isEmpty(dateString)
      ? new Date(dateString).toISOString()
      : undefined
  }

  const setPatientProfile = (index: number): void => {
    const patient = patientProfiles[index]
    if (patient) {
      patient.birth_date = convertToISOFormat(patient?.birth_date ?? '')
      reset(patient)
      setSelectedProfileIndex(index)
    }
  }

  useEffect(() => {
    if (patientProfiles.length === 1) {
      setPatientProfile(0)
    } else {
      setIsMultipleProfileFlow(true)
    }
  }, [patientProfiles])

  const handleProfileSelect = (index: number): void => {
    setPatientProfile(index)
  }

  const onSubmit = async (): Promise<void> => {
    await handleSubmit(async data => {
      if (!languageOptions.find(l => l.value === data.preferred_language)) {
        data.preferred_language = undefined
      }

      const patient = await createPatient(data)
      if (patient) {
        setCreatedPatient(patient)
        await updatePatientDemographicsQuery({
          created_patient_entry_index: selectedProfileIndex!,
          created_patient_id: patient.id,
          query_id: queryId,
        })
      }
    })()
  }

  if (!isNil(createdPatient)) {
    return (
      <ShowCreatedPatientAndCTA
        createdPatient={createdPatient}
        onClickGoToFirstStep={onClickGoToFirstStep}
      />
    )
  }

  if (!isNil(selectedProfileIndex)) {
    return (
      <ImportAndCreatePatientForm
        patientIdentifier={patientIdentifier}
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        goBack={() => {
          if (isMultipleProfileFlow) {
            setSelectedProfileIndex(undefined)
          } else {
            onClickGoToFirstStep()
          }
        }}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <ChooseFromMultiplePatientOptions
      patientIdentifier={patientIdentifier}
      patientProfiles={patientProfiles}
      onProfileSelect={handleProfileSelect}
      onClickGoToFirstStep={onClickGoToFirstStep}
    />
  )
}

ReviewAndCreatePatientStep.displayName = 'ReviewAndCreatePatientStep'
