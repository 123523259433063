import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useGetOrchestrationFactsFromPromptQuery } from '../../generated/types-orchestration'

interface UseGetAiAnswerHook {
  loading: boolean
  answer: string
}

export const useOrchestrationFactsPrompt = (
  pathwayId: string,
  prompt: string,
): UseGetAiAnswerHook => {
  const { t } = useTranslation()

  const { data, loading, error } = useGetOrchestrationFactsFromPromptQuery({
    variables: {
      pathway_id: pathwayId,
      prompt,
    },
    skip: isEmpty(prompt), // Skip the query if prompt is empty or falsy
  })

  return {
    loading,
    answer: !isNil(error)
      ? t('ava_gpt_default_error_message')
      : data?.getOrchestrationFactsFromPrompt?.response ?? '',
  }
}
