import { type RouteComponentProps, Router, useLocation } from '@reach/router'
import React, { type FC } from 'react'
import { HeadingSecondary, Text } from '@awell/ui-kit/components/Typography'
import { ContentContainer } from '@awell/ui-kit/components/ContentContainer'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import { LinkTab } from '@awell/ui-kit/components/Tabs'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { type PathwayDashboard } from '../../../generated/types-orchestration'
import { ClinicalDashboard } from '../ClinicalDashboard'
import { WebhooksCallList } from '../WebhookList/WebhooksCallList'
import { ApiCallList } from '../ApiCallList'
import { AuditLogs } from '../AuditLogs'

interface Props {
  dashboards?: PathwayDashboard | null
}
export const useDashboardStyles = makeStyles({
  container: {
    background: colors.neutralLight0,
    height: '100%',
  },
  tabsContainer: {
    display: 'flex',
    borderBottom: `2px solid ${colors.brand10}`,
    margin: `${spacing.s} 0`,
  },
})

export const PathwayLogsRoute: FC<RouteComponentProps & Props> = ({
  dashboards,
}) => {
  const classes = useDashboardStyles()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isCurrentTabPath = (tabName: string) => pathname.includes(tabName)

  return (
    <section className={classes.container}>
      <ContentContainer>
        <>
          <HeadingSecondary variant='subHeadline'>{t('logs')}</HeadingSecondary>
          <Text color={colors.neutralMid200}>{t('logs_description')}</Text>
          <div className={classes.tabsContainer}>
            <LinkTab
              tabLabel={t('api_call_plural')}
              selected={isCurrentTabPath('api-calls')}
              linkToLocation='api-calls'
              data-heap='pathway-api-call-tab'
              data-product-tour-target='pathway-api-call-tab'
            />
            <LinkTab
              tabLabel={t('webhook_plural')}
              selected={isCurrentTabPath('webhooks')}
              linkToLocation='webhooks'
              data-heap='care-pathway-webhook-tab'
              data-product-tour-target='pathway-case-webhook-tab'
            />
            <LinkTab
              tabLabel={t('audit_log_plural')}
              selected={isCurrentTabPath('audit-logs')}
              linkToLocation='audit-logs'
              data-heap='pathway-audit-logs-tab'
              data-product-tour-target='pathway-audit-logs-tab'
            />
            <LinkTab
              tabLabel={t('clinical_dashboard')}
              selected={isCurrentTabPath('clinical')}
              linkToLocation='clinical'
              data-heap='care-pathway-webhook-tab'
              data-product-tour-target='care-pathway-webhook-tab'
            />
          </div>
          <Router>
            <ClinicalDashboard dashboards={dashboards} path='clinical' />
            <WebhooksCallList path='webhooks' />
            <ApiCallList path='api-calls' />
            <AuditLogs path='audit-logs' />
          </Router>
        </>
      </ContentContainer>
    </section>
  )
}

PathwayLogsRoute.displayName = 'PathwayLogsRoute'
