import { CardHeader, makeStyles } from '@material-ui/core'
import React from 'react'
import { colors } from '../../utils/style-guide'

interface CareflowCardHeaderProps {
  title?: React.ReactNode
  subheader?: React.ReactNode
  action?: React.ReactNode
}

const useStyles = makeStyles({
  cardHeader: {
    backgroundColor: colors.brand10,
  },
})

export const CareflowCardHeader = ({
  title,
  subheader,
  action,
}: CareflowCardHeaderProps): JSX.Element => {
  const classes = useStyles()

  return (
    <CardHeader
      title={title}
      className={classes.cardHeader}
      subheader={subheader}
      disableTypography
      action={action}
    />
  )
}

CareflowCardHeader.displayName = 'CareflowCardHeader'
