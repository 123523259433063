import { DashboardSkeleton } from '@awell/ui-kit/components/Skeleton/DashboardSkeleton'
import {
  CareflowCard,
  CareflowCardDetails,
} from '@awell/ui-kit/components/CareflowCard'
import { type RouteComponentProps } from '@reach/router'
import React, { useEffect, type FC } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useCareflowDashboard } from '../../../hooks/useCareflowDashboard'
import { environment } from '@awell/libs-web/environment'
import Pagination from '@material-ui/lab/Pagination'
import { colors, spacing } from '@awell/ui-kit/utils/style-guide'
import theme from '@awell/ui-kit/utils/theme'
import { SearchForm } from '@awell/ui-kit/components/SearchForm'
import { Launch, Search } from '@awell/ui-kit/components/Icons'
import { Heading5, Text } from '@awell/ui-kit/components/Typography'
import { useTranslation } from 'react-i18next'
import { Badge } from '@awell/ui-kit/components/Badge'
import { Button } from '@awell/ui-kit/components/Button'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { truncateInside } from '@awell/ui-kit/utils'
import { Tooltip } from '@awell/ui-kit/components/Tooltip'
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard'

const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridGap: '1rem',
  },
  root: {
    flexGrow: 1,
  },
  careflowCard: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: spacing.m,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      gap: spacing.s,
    },
  },
  search: {
    minWidth: '300px',
  },
  pagination: {
    position: 'absolute',
    left: `calc(50% - 125px)`,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: 'auto',
    },
  },
})

export const CareflowsDashboard: FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { copyToClipboard } = useCopyToClipboard()

  const studio_url = environment.urls.pathway_studio
  const {
    careflowData = [],
    page,
    setPage,
    refetchDashboardData,
    loading,
    query,
    totalPages,
    setQuery,
  } = useCareflowDashboard()

  useEffect(() => {
    void refetchDashboardData(query)
  }, [page])

  const handleSearch = async (query: string): Promise<void> => {
    setQuery(query)
    await refetchDashboardData(query)
  }

  if (loading) {
    return <DashboardSkeleton />
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <SearchForm
          onSearch={handleSearch}
          debounced
          value={query}
          hideLabel
          iconEnd={<Search />}
          className={classes.search}
          autoFocus
        />
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, newPage) => {
              setPage(newPage)
            }}
            color='primary'
            className={classes.pagination}
          />
        )}
      </div>
      <Grid container spacing={2}>
        {careflowData.map(careflowDefinition => {
          const { id, title, version, release_id } = careflowDefinition
          const definition_id = id
          const link = `${studio_url}/pathways/${definition_id}`
          return (
            <Grid
              item
              key={id}
              xs={12}
              sm={6}
              md={4}
              className={classes.careflowCard}
            >
              <CareflowCard
                headerTitle={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: spacing.xxs,
                      marginBottom: spacing.xxxs,
                    }}
                  >
                    <Tooltip
                      info={title}
                      arrow
                      style={{ marginRight: spacing.xxxs }}
                    >
                      <span>
                        <Heading5>
                          {truncateInside({ max_length: 18 })(title)}
                        </Heading5>
                      </span>
                    </Tooltip>
                    <Badge kind='brand'>v{version}</Badge>
                  </div>
                }
                headerAction={
                  <Button href={link} target='_blank' variant='text'>
                    <span style={{ display: 'flex', gap: spacing.xxs }}>
                      <Text variant='textSmallBold' color={colors.blue500}>
                        {t('go_to_design')}
                      </Text>
                      <IconWrapper size='xxs' inline color={colors.blue500}>
                        <Launch />
                      </IconWrapper>
                    </span>
                  </Button>
                }
                headerSubheader={
                  <div
                    onClick={() => {
                      if (typeof release_id !== 'string') return
                      copyToClipboard(release_id)
                    }}
                    style={{ cursor: 'alias' }}
                  >
                    <Text variant='textTinyHint'>
                      {t('release_id')}: <code>{release_id}</code>
                    </Text>
                  </div>
                }
              >
                <CareflowCardDetails careflowDefinition={careflowDefinition} />
              </CareflowCard>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

CareflowsDashboard.displayName = 'CareflowsDashboard'
