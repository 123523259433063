import React, { type FC } from 'react'
import { ActivityFeedCard } from '../../components/ActivityFeedCard/ActivityFeedCard'
import {
  AwellActivity,
  type BaselineDataPoint,
} from '../../components/AwellActivity'

import { type Activity, ActivityObjectType, ActivityStatus } from './types'
import { useActivityContent } from './useActivityContent'

interface Props {
  activity: Activity
  selected: boolean
  onSelect?: () => void
  baselineDataPoints?: Array<BaselineDataPoint>
  pathwayStatusExplanation?: string
}

export const FeedItem: FC<Props> = ({
  activity,
  selected,
  onSelect = () => null,
  baselineDataPoints,
  pathwayStatusExplanation,
}) => {
  const { content, events } = useActivityContent(activity)
  const useActivityFeedCard =
    activity.isUserActivity ||
    [
      ActivityObjectType.Calculation,
      ActivityObjectType.EmrReport,
      ActivityObjectType.ApiCall,
      ActivityObjectType.ClinicalNote,
      ActivityObjectType.PluginAction,
    ].includes(activity.object.type)

  if (useActivityFeedCard) {
    return (
      <ActivityFeedCard
        content={content}
        events={events}
        completed={
          activity.status === ActivityStatus.Done ||
          activity.status === ActivityStatus.Expired
        }
        onSelect={onSelect}
        selected={selected}
      />
    )
  }

  return (
    <AwellActivity
      activity={activity}
      baselineDataPoints={baselineDataPoints}
      pathwayStatusExplanation={pathwayStatusExplanation}
    />
  )
}

FeedItem.displayName = 'FeedItem'
