import { FullHeightRouter } from '@awell/libs-web/routing'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { HeaderBodyLayout } from '@awell/ui-kit/layouts/HeaderBodyLayout'
import React, { type FC } from 'react'
import { Redirect, useLocation } from '@reach/router'
import { LabelInteractionContextProvider } from '@awell/ui-kit/compositions/ActivityFeed'
import { isNil } from 'lodash'
import { ErrorPage } from '@awell/ui-kit/compositions/ErrorPage'
import { useTranslation } from 'react-i18next'
import { usePathway, PathwayStatus } from '../../hooks/usePathway'
import { PathwayLogsRoute } from './routes/PathwayLogsRoute'
import { PathwayActivityFeedRoute } from './routes/PathwayActivityFeedRoute'
import { BaselineInfoFormContainer } from '../../components/BaselineInfoForm'
import { PathwayTracksContextProvider } from './PathwayTracksContext'
import { useWhoami } from '../../hooks/useWhoami'
import { PathwaySidePanel } from './PathwaySidePanel'
import { PathwayHeader } from './PathwayHeader'
import { PathwayContextProvider } from '../../components/PathwayContextProvider'
import { PathwayTimelineRoute } from './routes/PathwayTimelineRoute'
import { WebhookDetails } from './WebhookDetails/WebhookDetails'
import { ApiCallDetails } from './ApiCallDetails'

interface Props {
  pathwayId: string
}

export const PathwayPage: FC<Props> = ({ pathwayId }) => {
  // FIXME: Refactor usePathway to provide pathway details (pathway name, patient object) only,
  // similar to usePathwayCase in Preview. Activities are handled by usePathwayContextProvider in individual views
  // Also, this component can get the pathwayId directly from usePathwayContext hook, no need to pass it in
  const { loading, pathway, error } = usePathway(pathwayId)
  const { loading: loadingCurrentUser } = useWhoami()
  const { t } = useTranslation()
  const location = useLocation()

  /** Timeline & activity feed have a fixed height */
  const bodyShouldHaveFixedHeight = () => {
    if (location.pathname.includes('logs')) {
      return false
    }

    return true
  }

  if (loading || loadingCurrentUser) {
    return <Spinner />
  }

  if (error || isNil(pathway)) {
    return (
      <ErrorPage
        labels={{
          buttonText: '',
          errorTitle: t('error_page_title'),
          errorContent: t('error_page_content'),
        }}
      />
    )
  }

  return (
    <LabelInteractionContextProvider>
      <PathwayTracksContextProvider tracks={pathway.tracks}>
        <PathwayContextProvider>
          <HeaderBodyLayout>
            <HeaderBodyLayout.Header>
              <PathwayHeader loading={loading} />
            </HeaderBodyLayout.Header>
            <HeaderBodyLayout.Body fixedHeight={bodyShouldHaveFixedHeight()}>
              {pathway?.status === PathwayStatus.MissingBaselineInfo ? (
                <BaselineInfoFormContainer pathwayId={pathwayId} />
              ) : (
                <FullHeightRouter>
                  <Redirect from='/' to='activity-feed' />
                  <PathwayActivityFeedRoute
                    path='activity-feed'
                    pathwayId={pathwayId}
                  />
                  <PathwayTimelineRoute path='timeline' pathwayId={pathwayId} />
                  <Redirect from='/logs' to='audit-logs' />
                  <PathwayLogsRoute
                    path='logs/*'
                    dashboards={pathway.dashboards}
                  />
                  <WebhookDetails
                    path='/logs/webhooks/:id'
                    pathwayId={pathwayId}
                  />
                  <ApiCallDetails
                    path='/logs/api-calls/:id'
                    pathwayId={pathwayId}
                  />
                </FullHeightRouter>
              )}
            </HeaderBodyLayout.Body>
          </HeaderBodyLayout>
          <PathwaySidePanel />
        </PathwayContextProvider>
      </PathwayTracksContextProvider>
    </LabelInteractionContextProvider>
  )
}

PathwayPage.displayName = 'PathwayPage'
