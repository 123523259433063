import { useLocation } from '@reach/router'
import React, { type CSSProperties, type FC } from 'react'
import { findChildByType } from '../../utils/findChildByType'
import { ActivityFeed, Dashboard, Timeline } from '../Icons'
import { LinkTab } from '../Tabs'
import { useStyles } from './useStyles'

interface SlotProps {
  style?: CSSProperties
}

interface Slots {
  Title: FC<SlotProps>
  Subtitle: FC<SlotProps>
  Action: FC<SlotProps>
  Tools: FC<SlotProps>
  Breadcrumbs: FC<SlotProps>
}

const Title: FC<SlotProps> = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.title} style={style}>
      {children}
    </div>
  )
}
const Breadcrumbs: FC<SlotProps> = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.breadcrumbs} style={style}>
      {children}
    </div>
  )
}
const Subtitle: FC<SlotProps> = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.subtitle} style={style}>
      {children}
    </div>
  )
}

const Action: FC<SlotProps> = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.action} style={style}>
      {children}
    </div>
  )
}
const Tools: FC<SlotProps> = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.headerTools} style={style}>
      {children}
    </div>
  )
}

/**
 * Renders a header with:
 * - Title slot (Pathway case name / Patient name)
 * - Sub title slot (Pathway name)
 * - Tabs for all available pathway views
 * - Tools slot for additional toolbar
 */
export const PathwayViewsHeader: FC & Slots = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const action = findChildByType(children, Action)
  const tools = findChildByType(children, Tools)
  const classes = useStyles()

  const { pathname } = useLocation()
  const isCurrentTabPath = (tabName: string) => pathname.includes(tabName)

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerBottom}>
        <div className={classes.tabButtons}>
          <LinkTab
            icon={<ActivityFeed />}
            tabLabel='Activity Feed'
            selected={isCurrentTabPath('activity-feed')}
            linkToLocation='activity-feed'
          />
          <LinkTab
            icon={<Timeline />}
            tabLabel='Timeline'
            selected={isCurrentTabPath('timeline')}
            linkToLocation='timeline'
          />
          <LinkTab
            icon={<Dashboard />}
            tabLabel='Logs'
            selected={isCurrentTabPath('logs')}
            linkToLocation='logs'
          />
        </div>
        {tools}

        {action}
      </div>
    </div>
  )
}

PathwayViewsHeader.displayName = 'PathwayViewsHeader'
PathwayViewsHeader.Title = Title
PathwayViewsHeader.Subtitle = Subtitle
PathwayViewsHeader.Tools = Tools
PathwayViewsHeader.Action = Action
PathwayViewsHeader.Breadcrumbs = Breadcrumbs
