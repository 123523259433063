import { type Column } from '@awell/ui-kit/components/Table'
import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkCell } from '@awell/ui-kit/components/Table/LinkCell'
import { type Patient } from '../../../hooks/usePatients'
import { defaultProfilePropTo } from '../../../utils/defaultProfilePropTo'
import { formatDate } from '../../../utils'
import { capitalize } from 'lodash'
import { IconButton } from '@awell/ui-kit/components/Button/IconButton'
import { Search } from '@awell/ui-kit/components/Icons'
import { useNavigate } from '@reach/router'

export const usePatientListColumns = (): {
  columns: Array<Column<Patient>>
} => {
  const { t } = useTranslation()

  const renderPatientName = (patient: Patient): ReactElement => {
    return (
      <LinkCell
        title={defaultProfilePropTo({
          prop: 'name',
          defaultValue: t('anonymous'),
        })(patient)}
        id={patient.id}
      />
    )
  }

  const renderDate = (patient: Patient): string => {
    return defaultProfilePropTo({
      prop: 'birth_date',
      formatFn: formatDate('dd/MM/yyyy'),
    })(patient)
  }

  const renderWorkingListLink = (patient: Patient): ReactElement => {
    const navigate = useNavigate()

    return (
      <IconButton
        tooltip={t('view_activities')}
        iconSize='xs'
        onClick={() => {
          void navigate(`/activities/patient/${patient.id}`)
        }}
      >
        <Search />
      </IconButton>
    )
  }

  const columns: Array<Column<Patient>> = [
    {
      title: t('patient_table_column_name'),
      field: 'name',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      width: 90,
      render: renderPatientName,
    },
    {
      title: t('patient_table_column_code'),
      field: 'activePatients',
      type: 'string',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (patient: Patient): string =>
        defaultProfilePropTo({ prop: 'patient_code' })(patient),
      width: 90,
    },
    {
      title: t('patient_table_column_birth'),
      field: 'allPatients',
      type: 'datetime',
      sorting: false,
      filtering: false,
      searchable: false,
      render: renderDate,
      width: 60,
    },
    {
      title: t('patient_table_column_email'),
      field: 'allPendingActivities',
      sorting: false,
      filtering: false,
      searchable: false,
      // eslint-disable-next-line react/display-name
      render: (patient: Patient): JSX.Element => (
        <span style={{ overflowWrap: 'anywhere' }}>
          {defaultProfilePropTo({ prop: 'email' })(patient)}
        </span>
      ),
      width: 120,
    },
    {
      title: t('patient_table_column_lang'),
      field: 'doneActivities',
      sorting: false,
      filtering: false,
      searchable: false,
      render: (patient: Patient): string =>
        defaultProfilePropTo({ prop: 'preferred_language' })(patient),
      width: 40,
    },
    {
      title: capitalize(t('patient_table_column_view_activities')),
      field: 'action',
      sorting: false,
      filtering: false,
      searchable: false,
      render: renderWorkingListLink,
      width: 30,
    },
  ]
  return { columns }
}
