/* eslint-disable react/require-default-props */
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { capitalize, isEmpty, isNil, map, snakeCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SingleSelectWithRadioButton } from '../../components/MultiSelectWithCount/SingleSelectWithRadioButton'
import { colors, spacing } from '../../utils/style-guide'
import { type OptionProps } from '../ComboBox'
import { Search } from '../Icons'
import { MultiSelectWithCount } from '../MultiSelectWithCount'
import { SearchForm } from '../SearchForm'
import { Checkbox } from '../SelectionControls'
import {
  type FilterVariants,
  type CheckboxSearchFilterBarFilters,
  type SelectSearchFilterBarFilters,
  type QueryFilter,
  type SingleSelectSearchFilterBarFilters,
  type DateTimeFilterBarFilters,
} from './types'
import { DateTimeInput } from '../DateTimeInput'

interface StyleProps {
  background?: string
  hideBorder?: boolean
  customSpacing?: string
}

interface SearchFilterBarProps<T> extends StyleProps {
  onSearchFilter: (filters: T) => void
  filters: Array<FilterVariants>
  appliedFilters: T
  debounced?: boolean
  placeholder?: string
  className?: string
  hideFreeTextSearch?: boolean
}

const useStyles = makeStyles({
  container: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    padding: (props: StyleProps) => props.customSpacing ?? spacing.xs,
    borderBottom: (props: StyleProps) =>
      props.hideBorder === true ? 'none' : `1px solid ${colors.neutralLight40}`,
    backgroundColor: (props: StyleProps) =>
      props.background ?? colors.neutralLight0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  searchBoxContainer: {
    marginRight: spacing.xs,
    minWidth: 320,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: spacing.xs,
    },
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: spacing.xs,
    },
  },
  checkBox: {
    whiteSpace: 'nowrap',
  },
})

export const SearchFilterBar = <T extends QueryFilter>({
  onSearchFilter,
  filters,
  debounced,
  appliedFilters,
  placeholder = 'Search',
  background,
  className,
  hideBorder = false,
  customSpacing,
  hideFreeTextSearch = false,
}: SearchFilterBarProps<T>): JSX.Element => {
  const classes = useStyles({ background, hideBorder, customSpacing })
  const { t } = useTranslation()

  if (isNil(appliedFilters)) return <></>

  const selectFilters = filters.filter(
    (filter: FilterVariants): filter is SelectSearchFilterBarFilters =>
      (filter as SelectSearchFilterBarFilters).type === 'select',
  )

  const singleSelectFilters = filters.filter(
    (filter: FilterVariants): filter is SingleSelectSearchFilterBarFilters =>
      (filter as SingleSelectSearchFilterBarFilters).type === 'single_select',
  )
  const checkboxFilters = filters.filter(
    (filter: FilterVariants): filter is CheckboxSearchFilterBarFilters =>
      (filter as CheckboxSearchFilterBarFilters).type === 'checkbox',
  )

  const dateTimeFilters = filters.filter(
    (filter: FilterVariants): filter is DateTimeFilterBarFilters =>
      (filter as DateTimeFilterBarFilters).type === 'date_time',
  )

  const handleSearchChange = (searchPhrase: string): void => {
    const filtersClone = { ...appliedFilters, query: searchPhrase }
    onSearchFilter(filtersClone)
  }

  const handleFilterChange = (
    selection: Array<OptionProps>,
    name: string,
  ): void => {
    const updatedFilters = {
      ...appliedFilters,
      [name]: map(selection, 'value'),
    }
    onSearchFilter(updatedFilters)
  }

  const handleSingleFilterChange = (
    selection: OptionProps,
    name: string,
  ): void => {
    const updatedFilters = {
      ...appliedFilters,
      [name]: selection?.value,
    }
    onSearchFilter(updatedFilters)
  }

  const handleDateFilterChange = (
    date: string | undefined,
    name: string,
  ): void => {
    const updatedFilters = {
      ...appliedFilters,
      [name]: date,
    }
    onSearchFilter(updatedFilters)
  }

  const handleCheckboxFilterToggle = (
    selection: boolean,
    name: string,
  ): void => {
    const updatedFilters = { ...appliedFilters, [name]: selection }
    onSearchFilter(updatedFilters)
  }

  return (
    <div className={classnames(classes.container, className)}>
      {!hideFreeTextSearch && (
        <SearchForm
          debounced={debounced}
          onSearch={value => {
            handleSearchChange(value)
          }}
          value={appliedFilters?.query ?? ''}
          hideLabel
          placeholder={placeholder}
          iconEnd={<Search />}
          className={classes.searchBoxContainer}
        />
      )}
      <div className={classes.filterContainer}>
        {selectFilters.map(
          ({
            label,
            name,
            options,
            disabled,
            defaultChecked,
            disabledTooltip,
          }) => {
            return (
              <MultiSelectWithCount
                compact
                key={name}
                id={snakeCase(name)}
                label={capitalize(label)}
                options={options}
                disabled={disabled ?? isEmpty(options)}
                disabledTooltip={
                  disabledTooltip ??
                  t('no_options', { name: label.toLowerCase() })
                }
                defaultSelectedOptions={defaultChecked}
                // @ts-expect-error combobox only accepts strings for value
                onChange={(_options: Array<OptionProps>) => {
                  handleFilterChange(_options, name)
                }}
              />
            )
          },
        )}
        {singleSelectFilters.map(
          ({
            label,
            name,
            options,
            disabled,
            defaultChecked,
            disabledTooltip,
            showClearButton = false,
          }) => {
            return (
              <SingleSelectWithRadioButton
                compact
                key={name}
                id={snakeCase(name)}
                label={capitalize(label)}
                options={options}
                disabled={disabled ?? isEmpty(options)}
                disabledTooltip={
                  disabledTooltip ??
                  t('no_options', { name: label.toLowerCase() })
                }
                hideClearButton={!showClearButton}
                defaultSelectedOption={defaultChecked}
                // @ts-expect-error combobox only accepts strings for value
                onChange={(_option: OptionProps) => {
                  handleSingleFilterChange(_option, name)
                }}
              />
            )
          },
        )}
        {dateTimeFilters.map(({ label, name, defaultValue, format }) => {
          return (
            <DateTimeInput
              key={name}
              label={capitalize(label)}
              onSelectDate={value => {
                handleDateFilterChange(value, name)
              }}
              date={defaultValue ?? undefined}
              format={format}
            />
          )
        })}
      </div>
      <div className={classes.checkboxContainer}>
        {checkboxFilters.map(({ label, name, disabled }) => {
          return (
            <Checkbox
              key={name}
              label={label}
              value={name}
              // @ts-expect-error no index signature when accessing by name
              checked={appliedFilters[name]}
              disabled={disabled}
              style={{
                // using style here because className overwrites styling of checkbox
                // TODO: change implementation of Checkbox component to accept className as prop
                whiteSpace: 'nowrap',
              }}
              onChange={e => {
                handleCheckboxFilterToggle(e.target.checked, name)
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
