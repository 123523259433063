import { get, isEmpty, isNil } from 'lodash'
import type { User, UserProfile } from './types'

export const defaultProfilePropTo =
  ({
    prop,
    defaultValue = '-',
    formatFn,
  }: {
    prop: keyof Omit<UserProfile, 'address'>
    defaultValue?: string
    formatFn?: (val: string) => string | undefined
  }) =>
  (patient: Partial<User>): string => {
    const val = get(patient, `profile.${prop}`)
    if (isNil(val) || isEmpty(val) || typeof val !== 'string') {
      return defaultValue
    }

    if (formatFn != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return formatFn(val) ?? defaultValue
    }
    return val
  }
