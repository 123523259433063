import { FullHeightRouter } from '@awell/libs-web/routing'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { type RouteComponentProps } from '@reach/router'
import React, { type FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CareAppPageLayout } from '../components/CareAppPageLayout'
import { CareflowsDashboard } from '../pages/Pathways/Dashboard'
import { withClinicalAppRedirect } from './withClinicalAppRedirect'

const CareflowsRoute: FC<RouteComponentProps> = () => {
  const { addBreadcrumbs } = useBreadcrumbs()
  const { t } = useTranslation()

  const breadcrumb = {
    label: t('careflow_plural'),
    url: '/careflows',
    level: 0,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  // Primary Routers will manage focus on location transitions so we set
  // it to false here, otherwise it scrolls to the inner content on load
  return (
    <FullHeightRouter primary={false}>
      <CareAppPageLayout path='/'>
        <CareflowsDashboard default path='/' />
      </CareAppPageLayout>
    </FullHeightRouter>
  )
}

CareflowsRoute.displayName = 'CareflowsRoute'

export default withClinicalAppRedirect(CareflowsRoute)
