import {
  debounce,
  Input as MaterialInput,
  InputAdornment,
} from '@material-ui/core'
import classnames from 'classnames'
import { format, parseISO } from 'date-fns'
import { isEmpty, isNil, noop, snakeCase } from 'lodash'
import { usePhoneInput } from 'react-international-phone'
import React, {
  useEffect,
  useRef,
  type KeyboardEventHandler,
  type RefObject,
} from 'react'
import { useStateSafe } from '../../hooks/useStateSafe'
import { colors } from '../../utils/style-guide'
import { IconButton } from '../Button'
import { Cross } from '../Icons'
import { IconWrapper } from '../IconWrapper'
import { Type, TypeVariants } from '../Typography'
import { FlagSelector } from './FlagSelector'
import { InputLabel } from './InputLabel'
import { useStyles } from './useStyles'
import { useCountryIso } from '../../hooks/useClientCountryIso'
import { type PhoneConfig } from '../FormBuilder'
import { type Maybe } from '../../utils'

interface Props
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'autoComplete' | 'autoFocus'
  > {
  value: string
  id?: string
  onChange: (value: string) => void
  onEnterPress?: KeyboardEventHandler
  onKeyPressCustomHandler?: KeyboardEventHandler
  onKeyUpCustomHandler?: KeyboardEventHandler
  onBlur?: () => void
  label?: string
  description?: string
  placeholder?: string
  unit?: string
  type?: string | 'date' | 'tel' | 'text' | 'number' | 'time'
  error?: string
  warning?: boolean
  disabled?: boolean
  iconStart?: JSX.Element
  min?: number | null
  rows?: number | string
  max?: number | null
  maxLength?: string
  multiline?: boolean
  iconEnd?: JSX.Element
  endElement?: JSX.Element
  hideLabel?: boolean
  compact?: boolean
  className?: string
  style?: React.CSSProperties
  debounced?: boolean
  withErrorSpace?: boolean
  dataCy?: string
  required?: boolean
  variant?: 'transparent' | 'default'
  inputRef?: RefObject<HTMLInputElement | null>
  onFocus?: () => void
  clearable?: boolean
  phoneConfig?: Maybe<PhoneConfig>
  onClear?: () => void
  readOnly?: boolean
}

export const InputField: React.FC<Props> = ({
  id,
  value,
  autoComplete = 'off',
  autoFocus = false,
  iconStart,
  iconEnd,
  endElement,
  unit,
  label,
  description,
  hideLabel,
  placeholder,
  min,
  max,
  maxLength,
  multiline,
  rows,
  disabled,
  onChange,
  onBlur,
  type: inputType = 'text',
  error,
  warning,
  compact,
  className = '',
  style,
  debounced = false,
  withErrorSpace = false,
  dataCy = null,
  onEnterPress,
  onKeyPressCustomHandler,
  required = false,
  variant = 'default',
  inputRef = null,
  onKeyUpCustomHandler = noop,
  onFocus = noop,
  clearable = false,
  phoneConfig,
  onClear,
  readOnly = false,
}) => {
  const isPhoneInput = inputType === 'tel'
  const isNotDateInput = inputType !== 'date'
  const { getCountriesList, getInitialCountry } = useCountryIso()
  const classes = useStyles({ variant })
  const [internalValue, setInternalValue] = useStateSafe(value)
  // Boolean to track if user is typing or not in the input field
  const [isTyping, setTyping] = useStateSafe(false)
  // Timer to unset isTyping value after a timeout
  const [typingTimer, setTypingTimer] = useStateSafe<null | ReturnType<
    typeof setTimeout
  >>(null)

  const {
    phone,
    handlePhoneValueChange,
    inputRef: phoneInputRef,
    country,
    setCountry,
  } = usePhoneInput({
    initialCountry: getInitialCountry(
      phoneConfig?.default_country as string | undefined,
    ),
    value:
      isPhoneInput && typeof internalValue === 'string' ? internalValue : '', // avoid passing non-phone values to this hook
    hideSpaceAfterDialCode: true,
    countries: getCountriesList(
      phoneConfig?.available_countries as Array<string> | undefined,
    ),
  })

  /**
   * For date type inputs, value must be formatted in yyyy-MM-dd or empty
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
   */
  const ensureCorrectDateFormat = (val: string): string => {
    if (isEmpty(val) || isNotDateInput) {
      return val ?? ''
    }
    const date = parseISO(val)
    return format(date, 'yyyy-MM-dd')
  }

  // The value prop is updated when the changes have been recorded by the backend.
  // If the user is still typing when that happens, the internal value should not
  // be refreshed as it is older than the latest changes typed by the user.
  const refreshInternalValue = (newValue: string, typing: boolean): void => {
    if (!typing) {
      setInternalValue(ensureCorrectDateFormat(newValue))
    }
  }
  useEffect(() => {
    refreshInternalValue(value, isTyping)
    return () => {
      // To reset the timer when this component is destroyed
      if (!isNil(typingTimer)) {
        clearTimeout(typingTimer)
      }
    }
  }, [value, isTyping])

  const DEBOUNCE_DELAY = 1000
  const debouncedOnChange = useRef(debounce(onChange, DEBOUNCE_DELAY)).current

  // Record changes in internal state plus sets isTyping for 2 seconds
  // to deliver a smooth UX to users
  // => Prevents the input value to jump back while user is still typing
  const internalOnChange = (newValue: string): void => {
    if (!isNil(typingTimer)) {
      clearTimeout(typingTimer)
    }
    setTyping(true)
    setTypingTimer(
      setTimeout(() => {
        setTyping(false)
      }, 2000),
    )

    setInternalValue(newValue)
    if (debounced) {
      debouncedOnChange(newValue)
    } else {
      onChange(newValue)
    }
  }

  // prevent Enter key from triggering an action when input field is blank
  const internalOnKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' && internalValue === '') {
      event.preventDefault()
    }
    if (event.key === 'Enter' && !isNil(onEnterPress)) {
      onEnterPress(event)
    }
    if (!isNil(onKeyPressCustomHandler)) {
      onKeyPressCustomHandler(event)
    }
  }

  const startAdornment = !isNil(iconStart) && (
    <InputAdornment position='start'>
      <IconWrapper bare>{iconStart}</IconWrapper>
    </InputAdornment>
  )

  const flagSelectorStartAdornment = isPhoneInput ? (
    <InputAdornment position='start'>
      <FlagSelector
        defaultCountries={getCountriesList(
          phoneConfig?.available_countries as Array<string> | undefined,
        )}
        country={country}
        setCountry={setCountry}
        id={id ?? ''}
      />
    </InputAdornment>
  ) : null

  const endAdornment = (!isNil(iconEnd) ||
    !isNil(unit) ||
    !isNil(endElement)) && (
    <InputAdornment position='end'>
      {endElement}
      {unit}
      {!isNil(iconEnd) && <IconWrapper bare>{iconEnd}</IconWrapper>}
    </InputAdornment>
  )

  const clearEndAdornment: JSX.Element = (
    <InputAdornment position='end'>
      <IconButton
        variant='floating'
        onClick={() => {
          onChange('')
          onClear?.()
        }}
        className={classes.clearButton}
      >
        <Cross />
      </IconButton>
    </InputAdornment>
  )

  // On some places we want to always render space for error, so we don't
  // have jumps in UI(forms), on other places we don't have errors, or
  // don't have jumps in UI, so rendering this space just adds unnecessary height
  const shouldRenderErrorSpace = !isEmpty(error) ?? withErrorSpace
  const shouldShowLabel = !isNil(label) && !(hideLabel ?? false)
  const getPlaceholder = (): string => {
    if (!isNil(placeholder)) {
      return placeholder
    }
    if (hideLabel ?? false) {
      return ''
    }
    if (!isNil(label)) {
      return label ?? ''
    }
    return ''
  }

  const inputIsDisabled = disabled ?? false

  return (
    <div className={className} style={style} data-cy={dataCy} id={id}>
      {shouldShowLabel ? (
        <InputLabel
          htmlFor={snakeCase(label)}
          label={label}
          description={description}
          required={required}
        />
      ) : null}
      {inputIsDisabled && isNotDateInput ? (
        <div className={classes.plainDisabled}>
          {isNil(value) || isEmpty(value) ? '-' : value}
        </div>
      ) : (
        <MaterialInput
          id={snakeCase(label)}
          fullWidth
          inputRef={isPhoneInput ? phoneInputRef : inputRef}
          disableUnderline
          className={classnames(classes.hover, {
            error,
            [classes.warning]: warning,
          })}
          classes={{
            root: classes[compact ?? false ? 'inputRootCompact' : 'inputRoot'],
            disabled: classes.disabled,
            focused: classes.focused,
          }}
          style={{
            ...(variant === 'transparent' ? { backgroundColor: 'transparent' } : {}),
          }}
          value={isPhoneInput ? phone : internalValue}
          onChange={event => {
            if (isPhoneInput) {
              const value = handlePhoneValueChange(event)
              internalOnChange(value)
            } else {
              internalOnChange(event.target.value)
            }
          }}
          onKeyPress={event => {
            if (isPhoneInput) {
              return
            }
            internalOnKeyPress(event)
          }}
          onKeyUp={event => {
            if (isPhoneInput) {
              return
            }
            onKeyUpCustomHandler(event)
          }}
          onBlur={onBlur}
          placeholder={getPlaceholder()}
          type={inputType}
          inputProps={
            isPhoneInput
              ? {}
              : {
                  min,
                  // Due to native date input bug, year values of > 4 digits are allowed. To avoid wrong
                  // values and circumvent validation requirements now, we set a max date (year 9999)
                  // for date inputs - https://github.com/mui-org/material-ui/issues/10675
                  // NB: this appears to only restrict max digits to 4, not actually limit the value,
                  // hence why this is not set lower (e.g. 3000)
                  max: inputType === 'date' && isNil(max) ? '9999-12-31' : max,
                  maxLength,
                }
          }
          disabled={disabled}
          readOnly={readOnly}
          multiline={multiline}
          rows={rows}
          startAdornment={
            isPhoneInput ? flagSelectorStartAdornment : startAdornment
          }
          endAdornment={clearable ? clearEndAdornment : endAdornment}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onFocus={onFocus}
        />
      )}

      {shouldRenderErrorSpace ? (
        <div className={classes.error}>
          <Type variant={TypeVariants.small} color={colors.signalError100}>
            {error}
          </Type>
        </div>
      ) : null}
    </div>
  )
}
