import { type ApolloQueryResult } from '@apollo/client'
import { useGetPatientsQuery, type Patient } from './types'
import { useNotifications } from '../useNotifications'
import type {
  GetPatientsQuery,
  GetPatientsQueryVariables,
} from '../useCreatePatient/types'

interface UsePatientsHook {
  loading: boolean
  patients?: Array<Patient>
  pagination?: GetPatientsQuery['patients']['pagination']
  sorting?: GetPatientsQuery['patients']['sorting']
  refetchPatients: (
    query?: Partial<GetPatientsQueryVariables>,
  ) => Promise<ApolloQueryResult<GetPatientsQuery>>
}

export const usePatients = (): UsePatientsHook => {
  const { notifyError } = useNotifications()
  const {
    data,
    loading,
    error,
    refetch: refetchPatients,
  } = useGetPatientsQuery({
    variables: {
      filters: {
        search: {
          contains: '',
        }
      },
      pagination: {
        count: 10,
        offset: 0,
      },
      sorting: {
        field: 'name',
        direction: 'ASC',
      },
    },
  })

  if (error != null) {
    notifyError({
      message: 'Something went wrong while loading the patients list',
      error,
    })
    return { loading: false, refetchPatients }
  }
  if (loading) {
    return { loading: true, refetchPatients }
  }

  return {
    loading: false,
    patients: data?.patients.patients,
    pagination: data?.patients.pagination,
    sorting: data?.patients.sorting,
    refetchPatients,
  }
}
