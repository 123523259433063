import React from 'react'
import { Card, CardContent, makeStyles } from '@material-ui/core'
import { CareflowCardHeader } from './CareflowCardHeader'

const useStyles = makeStyles({
  card: {
    minHeight: 200,
    overflowY: 'hidden',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
})

interface CareflowCardProps {
  children: React.ReactNode
  headerAction?: React.ReactNode
  headerSubheader?: React.ReactNode
  headerTitle?: React.ReactNode
}

export const CareflowCard: React.FC<CareflowCardProps> = ({
  children,
  headerAction,
  headerSubheader,
  headerTitle,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Card {...props} className={classes.card}>
      <CareflowCardHeader
        title={headerTitle}
        subheader={headerSubheader}
        action={headerAction}
      />
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        {children}
      </CardContent>
    </Card>
  )
}

CareflowCard.displayName = 'CareflowCard'
