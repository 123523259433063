import { FullHeightRouter, LogoutRoute } from '@awell/libs-web/routing'
import { ErrorPage as NotFound } from '@awell/ui-kit/compositions/ErrorPage'
import React from 'react'
import { Redirect } from '@reach/router'
import CareflowsRoute from './CareflowsRoute'
import PatientsRoute from './PatientsRoute'
import { PathwayRoute } from './PathwayRoute'
import { Home } from './Home'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { LogsRoute } from './LogsRoute'
import ActivitiesRoute from './ActivitiesRoute'
import { Spinner } from '@awell/ui-kit/components/Spinner'

export const Routes: React.FC = () => {
  return (
    <ErrorBoundary>
      <FullHeightRouter>
        <Home path='/home' />
        <PathwayRoute path='/pathway/:pathwayId/*' />
        <ActivitiesRoute path='/activities/*' />
        <PatientsRoute path='/patients/*' />
        <CareflowsRoute path='/careflows/*' />
        <LogsRoute path='/logs/*' />
        <LogoutRoute loadingComponent={<Spinner />} path='/logout' />
        <NotFound sendHome default />
        <Redirect from='/' to='/patients' />
      </FullHeightRouter>
    </ErrorBoundary>
  )
}
