import { isNil } from 'lodash'
import { useState, useEffect } from 'react'
import {
  type PaginationOutput,
  type SortingOutput,
  type CareflowDashboardData,
  useGetCareflowDashboardQuery,
} from './types'

interface PathwayDefinitionsData {
  careflowData: Array<CareflowDashboardData>
  sorting: SortingOutput
  loading: boolean
  refetchDashboardData: (query: string) => Promise<void>
  setPage: (page: number) => void
  setQuery: (query: string) => void
  page: number
  query: string
  totalPages: number
}

const CARDS_PER_PAGE = 9

export const useCareflowDashboard = (): PathwayDefinitionsData => {
  const [data, setData] = useState<Array<CareflowDashboardData>>([])
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')
  const [sorting, setSorting] = useState<SortingOutput>({
    direction: 'DESC',
    field: 'release_date',
  })
  const [pagination, setPagination] = useState<PaginationOutput>({
    count: CARDS_PER_PAGE,
    offset: 0,
  })
  const [loading, setLoading] = useState(false)

  const { loading: queryLoading, refetch } = useGetCareflowDashboardQuery({
    variables: {
      pagination: {
        count: CARDS_PER_PAGE,
        offset: (page - 1) * CARDS_PER_PAGE,
      },
    },
    skip: true, // Skip the initial query, fetch only on refetchDashboardData call
  })

  const refetchDashboardData = async (query: string): Promise<void> => {
    setLoading(true)
    setQuery(query)
    const { data: newData } = await refetch({
      pagination: {
        count: CARDS_PER_PAGE,
        offset: (page - 1) * CARDS_PER_PAGE,
      },
      filters: {
        search: {
          contains: query,
        },
      },
    })
    setLoading(false)

    const { publishedPathwayDefinitions, pagination, sorting } =
      newData?.publishedPathwayDefinitionsDashboard ?? {}

    if (!isNil(pagination)) {
      setPagination(pagination)
    }
    if (!isNil(sorting)) {
      setSorting(sorting)
    }
    if (!isNil(publishedPathwayDefinitions)) {
      setData(publishedPathwayDefinitions)
    }
  }

  useEffect(() => {
    void refetchDashboardData(query)
  }, [page])

  return {
    careflowData: data,
    query,
    totalPages:
      !isNil(pagination.total_count) && !isNil(pagination.count)
        ? Math.ceil(pagination.total_count / 9)
        : 1,
    sorting,
    loading: loading || queryLoading,
    page,
    setPage,
    setQuery,
    refetchDashboardData,
  }
}
