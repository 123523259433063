import { makeStyles } from '@material-ui/core'
import { Done, Person, Settings } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LightningBolt } from 'styled-icons/heroicons-solid'
import { spacing } from '../../utils/style-guide'
import { IconWrapper } from '../IconWrapper'
import { Tooltip } from '../Tooltip'
import { type CareflowDashboardData } from './types'
import { Text } from '../Typography'
import { Button } from '../Button'

interface CareflowCardDetailsProps {
  careflowDefinition: CareflowDashboardData
}

const useStyles = makeStyles({
  dataIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxs,
    '&:first-of-type': {
      marginBottom: spacing.xs,
    },
  },
  dataIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  rotateAnimation: {
    transform: 'rotate(0deg)',
    transition: 'transform 6s linear',
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xs,
    justifyContent: 'flex-end',
    marginTop: spacing.s,
  },
  cardDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '&:hover $rotateAnimation': {
      transform: 'rotate(360deg)',
    },
  },
  cardCareflowCounts: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const DataIcon = ({
  children,
  count,
  tooltip,
  rotate = false,
}: {
  tooltip: string
  count: string | number
  children: React.ReactNode
  rotate?: boolean
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Tooltip info={tooltip}>
      <span className={classes.dataIcon}>
        <IconWrapper
          size='xs'
          inline
          color='inherit'
          className={rotate ? classes.rotateAnimation : undefined}
        >
          {children}
        </IconWrapper>
        {count}
      </span>
    </Tooltip>
  )
}

export const CareflowCardDetails = ({
  careflowDefinition,
}: CareflowCardDetailsProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { id, all, latest } = careflowDefinition

  return (
    <div key={id} className={classes.cardDetails}>
      <div>
        <Text variant='discreetHeadline'>{t('all_versions')}</Text>
        <div className={classes.dataIcons}>
          <DataIcon
            count={all?.total_patients ?? 0}
            tooltip={t('patients_enrolled')}
          >
            <Person />
          </DataIcon>
          <DataIcon
            count={all?.total_careflows ?? 0}
            tooltip={t('careflows_started')}
          >
            <LightningBolt />
          </DataIcon>
          <DataIcon
            count={all?.active_careflows ?? 0}
            tooltip={t('careflows_active')}
            rotate
          >
            <Settings />
          </DataIcon>
          <DataIcon
            count={all?.completed_careflows ?? 0}
            tooltip={t('careflows_completed')}
          >
            <Done />
          </DataIcon>
        </div>
        <Text variant='discreetHeadline'>{t('latest_version')}</Text>
        <div className={classes.dataIcons}>
          <DataIcon
            count={latest?.total_patients ?? 0}
            tooltip={t('patients_enrolled')}
          >
            <Person />
          </DataIcon>
          <DataIcon
            count={latest?.total_careflows ?? 0}
            tooltip={t('careflows_started')}
          >
            <LightningBolt />
          </DataIcon>
          <DataIcon
            count={latest?.active_careflows ?? 0}
            tooltip={t('careflows_active')}
            rotate
          >
            <Settings />
          </DataIcon>
          <DataIcon
            count={latest?.completed_careflows ?? 0}
            tooltip={t('careflows_completed')}
          >
            <Done />
          </DataIcon>
        </div>
      </div>
      <div className={classes.actionBar}>
        <Button href={`/activities/${id}`} variant='outlined'>
          {t('view_activities')}
        </Button>
      </div>
    </div>
  )
}

CareflowCardDetails.displayName = 'CareflowCardDetails'
