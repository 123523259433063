import React, { type FC } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Ribbon } from '../Ribbon/Ribbon'
import { Skeleton } from './Skeleton'
import theme from '../../utils/theme'
import { spacing } from '../../utils/style-guide'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles({
  card: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing.m,
  },
  skeletonContainer: {
    marginTop: spacing.s,
  },
})

export const DashboardSkeleton: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.skeletonContainer}>
      <Ribbon noBorder>
        <>
          <div className={classes.pagination}>
            <Pagination count={4} page={1} color='primary' />
          </div>
          <Grid container spacing={2}>
            {Array.from(Array(9).keys()).map(i => {
              return (
                <Grid
                  key={i}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={classes.card}
                >
                  <Skeleton
                    variant='rect'
                    width={300}
                    height={150}
                    key={i}
                    animation='pulse'
                  />
                </Grid>
              )
            })}
          </Grid>
        </>
      </Ribbon>
    </div>
  )
}
DashboardSkeleton.displayName = 'DashboardSkeleton'
