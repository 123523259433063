import { RouteComponentProps } from '@reach/router'
import React, { FC } from 'react'
import { PathwayActivityFeed } from '../../../components/PathwayActivityFeed'

interface Props {
  pathwayId: string
}

export const PathwayActivityFeedRoute: FC<RouteComponentProps & Props> = ({
  pathwayId,
}) => {
  return <PathwayActivityFeed pathwayId={pathwayId} />
}

PathwayActivityFeedRoute.displayName = 'PathwayActivityFeedRoute'
