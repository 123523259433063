import { type RouteComponentProps } from '@reach/router'
import React, { type FC, useState } from 'react'
import {
  type Column,
  Table,
  type TableQuery,
  type TableQueryResult,
} from '@awell/ui-kit/components/Table'
import { FullPageListSkeleton } from '@awell/ui-kit/components/Skeleton/FullPageListSkeleton'
import { useDebouncedCallback } from 'use-debounce'
import { usePatients } from '../../../hooks/usePatients'
import { usePatientListColumns } from './usePatientListColumns'
import { type Patient } from '../types'

export const PatientList: FC<RouteComponentProps> = () => {
  const { columns } = usePatientListColumns()
  const { loading, refetchPatients } = usePatients()
  const [searchTerm, setSearchTerm] = useState('')
  // Ref to the material-table component, no type available so setting as any
  const tableRef = React.createRef<any>()
  const debouncedTableRefresh = useDebouncedCallback(
    () => tableRef?.current?.onQueryChange(),
    1000,
  )

  if (loading) {
    return (
      <FullPageListSkeleton<Column<Patient>>
        hasHeaderButton={false}
        hasHeaderSubtitle={false}
        cellCount={columns.length}
        columns={columns}
      />
    )
  }

  const onSearch = (query: string): void => {
    setSearchTerm(query)
    debouncedTableRefresh()
  }

  const handleQueryChange = async (
    query: TableQuery<Patient>,
  ): Promise<TableQueryResult<Patient>> => {
    const { page, pageSize } = query
    const offset = page * pageSize
    const count = pageSize
    const {
      data: {
        patients: { patients, pagination },
      },
    } = await refetchPatients({
      filters: {
        search: {
          contains: searchTerm,
        },
      },
      pagination: {
        count,
        offset,
      },
    })
    return {
      data: patients,
      totalCount: pagination?.total_count ?? 0,
      page,
    }
  }

  return (
    <Table
      tableRef={tableRef}
      rows={handleQueryChange}
      columns={columns}
      onSearch={onSearch}
      paging
      fullWidth
      compactCells={false}
    />
  )
}

PatientList.displayName = 'PatientList'
