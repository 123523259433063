import { RouteComponentProps } from '@reach/router'
import React, { FC } from 'react'
import { currentPageTitleVar } from '../local-state'
import { Homepage } from '../pages/Home'

// TODO when home route gets re-enabled we need to redirect to clinical app here like in PathwaysRoute
export const Home: FC<RouteComponentProps> = () => {
  currentPageTitleVar('Awell Care')
  return <Homepage />
}

Home.displayName = 'Home'
